import React from 'react'
import PropTypes from 'prop-types'

const Label = ({ labelTitle, name, type, onChange, value, required, className }) => {
  return (
    <div className={`${className} form-group`}>
      <label htmlFor={name}>
        <h4>{labelTitle}</h4>
      </label>
      <input
        type={type}
        name={name}
        required={required}
        onChange={onChange}
        value={value}
        className="input"
      />
    </div>
  )
}

export default Label

Label.propTypes = {
  labelTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string
}

Label.defaultProps = {
  onChange: () => {},
  required: false,
  className: ''
}
