import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Recaptcha from 'react-google-recaptcha'

import useFormHooks from './CustomHooks/FormHooks'
import Label from './components/Label'

const ContactForm = ({ className }) => {
  const [validRecaptcha, setValidRecaptcha] = useState(false)

  const encode = data => {
    return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&')
  }

  const { inputs, handleInputChange, handleSubmit, clearInputs } = useFormHooks(() => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact-MI', ...inputs })
    })
      .then(() => {
        alert('Thank you for your submission!')
        clearInputs()
      })
      .catch(() => alert('Something went wrong, please try again'))
  })

  return (
    <section className={`mb-2 ${className}`}>
      <form
        name="contact-MI"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact-MI" />
        <Label
          labelTitle="Full Name"
          name="fullName"
          type="text"
          required
          onChange={handleInputChange}
          value={inputs.fullName}
        />

        <Label
          labelTitle="Email"
          name="email"
          type="email"
          required
          onChange={handleInputChange}
          value={inputs.email}
        />

        <Label
          labelTitle="Subject"
          type="text"
          name="subject"
          required
          onChange={handleInputChange}
          value={inputs.subject}
        />

        <div className="py-10 mb-2">
          <label htmlFor="message" style={{ width: '100%' }}>
            <h3>Your Message</h3>
            <div>
              <textarea
                rows="10"
                className="border border-primarygray rounded w-full"
                name="message"
                required
                onChange={handleInputChange}
                value={inputs.message}
              />
            </div>
          </label>
        </div>

        <Recaptcha
          sitekey={process.env.GATSBY_RECAPTCHA_KEY}
          onChange={() => setValidRecaptcha(true)}
          className="mb-2"
        />

        <button
          type="submit"
          className={`btn  ${!validRecaptcha ? 'btn-disabled' : 'btn-primary'}`}
          disabled={!validRecaptcha}
        >
          Contact Us!
        </button>
      </form>
    </section>
  )
}

export default ContactForm

ContactForm.propTypes = {
  className: PropTypes.string
}

ContactForm.defaultProps = {
  className: ''
}
